<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <form class="row justify-content-between">
          <div class="col-md-2">
            <v-select
              label="title"
              required
              v-model="perPage"
              :options="perPageOptions"
              @input="setPerPageSelected"
              :clearable="false"
            />
          </div>
          <div class="col-md-4">
            <input
              type="search"
              class="d-inline-block form-control"
              placeholder="Pesquisar..."
              v-model="filter"
            />
          </div>
        </form>
      </div>

      <b-table
        :sticky-header="true"
        :no-border-collapse="true"
        class="position-relative"
        :items="acqurires"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="Nenhum registro encontrado"
        empty-filtered-text="Nenhum registro encontrado"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        striped
        hover
      >
        <template #cell(created_at)="data">
          {{ data.item.created_at | datePT }}
        </template>

        <template #cell(name)="data">
          <div
            class="d-flex-left"
            v-b-tooltip.hover.top="data.item.active ? 'Ativo' : 'Inativo'"
          >
            <i
              style="font-size: 0.7rem"
              class="bi bi-circle-fill mr-50"
              :class="data.item.active ? 'text-success' : 'text-danger'"
            ></i>
            <span>
              {{ data.item.name }}
            </span>
          </div>
        </template>

        <template #cell(units)="data">
          <div class="d-flex-center">
            <b-avatar-group
              size="26"
              class="ml-1"
              v-if="data.item.units.length > 1"
            >
              <b-avatar
                v-for="(unit, i) in data.item.units"
                :key="i"
                triggers="hover"
                class="pull-up"
                variant="warning"
                v-b-tooltip.hover.top="`${unit.name}`"
              >
                {{ getFirstLetters(unit.name) }}
              </b-avatar>
            </b-avatar-group>

            <div v-else>
              <b-avatar
                v-for="(unit, i) in data.item.units"
                :key="i"
                triggers="hover"
                class="pull-up"
                variant="warning"
                v-b-tooltip.hover.top="`${unit.name}`"
              >
                {{ getFirstLetters(unit.name) }}
              </b-avatar>
            </div>
          </div>
        </template>

        <template #cell(actions)="data">
          <div class="d-flex" style="gap: 15px">
            <!-- <feather-icon
              icon="TrashIcon"
              class="cursor-pointer cursor text-danger"
              size="16"
               v-if="$can('Excluir', 'Adquirentes')"
              @click="confirmDelete(data.item.uuid)"
            /> -->

            <feather-icon
              icon="CopyIcon"
              class="cursor-pointer cursor"
              size="16"
              v-b-tooltip.hover.top="'Duplicar'"
              v-if="$can('Duplicar', 'Adquirentes')"
              @click="
                $router.push({
                  name: 'acquirers-create',
                  params: { uuid: data.item.uuid },
                })
              "
            />

            <router-link
              v-if="$can('Editar', 'Adquirentes')"
              :to="{
                name: 'acquirers-edit',
                params: { uuid: data.item.uuid },
              }"
            >
              <feather-icon
                icon="EditIcon"
                class="cursor-pointer cursor"
                size="16"
              />
            </router-link>
          </div>
        </template>
      </b-table>

      <div class="mx-2 mb-2 mt-2 paginate-area">
        <div class="row">
          <div
            class="d-flex align-items-center justify-content-center justify-content-sm-start col-md-6"
          >
            <span class=""> Foram encontrados {{ totalRows }} resultados </span>
          </div>
          <div
            class="col-md-6 d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              :limit="10"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="my-1"
              @input="updatePage()"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BAvatarGroup,
  BCard,
  BTable,
  BPagination,
  BCardText,
  VBTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BCard,
    BTable,
    BPagination,
    BCardText,
    vSelect,
    BAvatar,
    BAvatarGroup,
  },
  data() {
    return {
      optionsCardNetworks: [],
      acqurires: [],
      filter: null,
      filterOn: [],
      currentPage: 1,
      perPage: 100,
      totalRows: 0,
      rowsTable: 0,
      perPageOptions: [
        {
          title: "100",
          value: 100,
        },
        {
          title: "200",
          value: 200,
        },
      ],
      tableColumns: [
        {
          key: "name",
          label: "Nome",
        },
        {
          key: "units",
          label: "Unidades",
          class: "text-center",
          thClass: "text-center",
        },
        {
          key: "created_at",
          label: "Cadastrado",
          class: "text-center",
          thClass: "text-center",
          thStyle: "width: 190px",
        },
        {
          key: "actions",
          label: "",
          thClass: "text-center td-actions",
          thStyle: "width: 80px",
        },
      ],
    };
  },
  methods: {
    getFirstLetters(inputString) {
      const words = inputString.split(" ");
      const firstLetters = words.map((word) => word.charAt(0).toUpperCase());
      return firstLetters.join("");
    },
    setPerPageSelected(obj) {
      this.perPage = obj.value;
      this.getData();
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    confirmDelete(uuid) {
      this.$swal({
        title: "Tem certeza?",
        text: "Só será possível deletar caso o item não esteja sendo usado.",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero deletar!",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("Acquirer/delete", { uuid: uuid }).then(() => {
            this.getData();
            this.$swal({
              icon: "success",
              title: "Deletado!",
              text: "Operaçaõ realizada com sucesso.",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          });
        }
      });
    },
    updatePage() {
      this.getData();
    },
    async getData() {
      this.$store
        .dispatch("Acquirer/all", {
          currentPage: this.currentPage,
          perPage: this.perPage,
        })
        .then((res) => {
          if (res.data.length) {
            this.acqurires = res.data;
            this.dataItems = res.data;
            this.rowsTable = res.data.length;
            this.totalRows = res.meta.total;
            this.currentPage = res.meta.current_page;
          }
        });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss">
.actions-icons {
  svg {
    margin: 5px 4px;
  }
}
</style>
