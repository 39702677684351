var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { staticClass: "mb-0", attrs: { "no-body": "" } },
        [
          _c("div", { staticClass: "m-2" }, [
            _c("form", { staticClass: "row justify-content-between" }, [
              _c(
                "div",
                { staticClass: "col-md-2" },
                [
                  _c("v-select", {
                    attrs: {
                      label: "title",
                      required: "",
                      options: _vm.perPageOptions,
                      clearable: false,
                    },
                    on: { input: _vm.setPerPageSelected },
                    model: {
                      value: _vm.perPage,
                      callback: function ($$v) {
                        _vm.perPage = $$v
                      },
                      expression: "perPage",
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "col-md-4" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filter,
                      expression: "filter",
                    },
                  ],
                  staticClass: "d-inline-block form-control",
                  attrs: { type: "search", placeholder: "Pesquisar..." },
                  domProps: { value: _vm.filter },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.filter = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
          ]),
          _c("b-table", {
            staticClass: "position-relative",
            attrs: {
              "sticky-header": true,
              "no-border-collapse": true,
              items: _vm.acqurires,
              responsive: "",
              fields: _vm.tableColumns,
              "primary-key": "id",
              "show-empty": "",
              "empty-text": "Nenhum registro encontrado",
              "empty-filtered-text": "Nenhum registro encontrado",
              filter: _vm.filter,
              "filter-included-fields": _vm.filterOn,
              striped: "",
              hover: "",
            },
            on: { filtered: _vm.onFiltered },
            scopedSlots: _vm._u([
              {
                key: "cell(created_at)",
                fn: function (data) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm._f("datePT")(data.item.created_at)) + " "
                    ),
                  ]
                },
              },
              {
                key: "cell(name)",
                fn: function (data) {
                  return [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover.top",
                            value: data.item.active ? "Ativo" : "Inativo",
                            expression:
                              "data.item.active ? 'Ativo' : 'Inativo'",
                            modifiers: { hover: true, top: true },
                          },
                        ],
                        staticClass: "d-flex-left",
                      },
                      [
                        _c("i", {
                          staticClass: "bi bi-circle-fill mr-50",
                          class: data.item.active
                            ? "text-success"
                            : "text-danger",
                          staticStyle: { "font-size": "0.7rem" },
                        }),
                        _c("span", [
                          _vm._v(" " + _vm._s(data.item.name) + " "),
                        ]),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "cell(units)",
                fn: function (data) {
                  return [
                    _c(
                      "div",
                      { staticClass: "d-flex-center" },
                      [
                        data.item.units.length > 1
                          ? _c(
                              "b-avatar-group",
                              { staticClass: "ml-1", attrs: { size: "26" } },
                              _vm._l(data.item.units, function (unit, i) {
                                return _c(
                                  "b-avatar",
                                  {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover.top",
                                        value: "" + unit.name,
                                        expression: "`${unit.name}`",
                                        modifiers: { hover: true, top: true },
                                      },
                                    ],
                                    key: i,
                                    staticClass: "pull-up",
                                    attrs: {
                                      triggers: "hover",
                                      variant: "warning",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.getFirstLetters(unit.name)) +
                                        " "
                                    ),
                                  ]
                                )
                              }),
                              1
                            )
                          : _c(
                              "div",
                              _vm._l(data.item.units, function (unit, i) {
                                return _c(
                                  "b-avatar",
                                  {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover.top",
                                        value: "" + unit.name,
                                        expression: "`${unit.name}`",
                                        modifiers: { hover: true, top: true },
                                      },
                                    ],
                                    key: i,
                                    staticClass: "pull-up",
                                    attrs: {
                                      triggers: "hover",
                                      variant: "warning",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.getFirstLetters(unit.name)) +
                                        " "
                                    ),
                                  ]
                                )
                              }),
                              1
                            ),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "cell(actions)",
                fn: function (data) {
                  return [
                    _c(
                      "div",
                      { staticClass: "d-flex", staticStyle: { gap: "15px" } },
                      [
                        _vm.$can("Duplicar", "Adquirentes")
                          ? _c("feather-icon", {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover.top",
                                  value: "Duplicar",
                                  expression: "'Duplicar'",
                                  modifiers: { hover: true, top: true },
                                },
                              ],
                              staticClass: "cursor-pointer cursor",
                              attrs: { icon: "CopyIcon", size: "16" },
                              on: {
                                click: function ($event) {
                                  return _vm.$router.push({
                                    name: "acquirers-create",
                                    params: { uuid: data.item.uuid },
                                  })
                                },
                              },
                            })
                          : _vm._e(),
                        _vm.$can("Editar", "Adquirentes")
                          ? _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "acquirers-edit",
                                    params: { uuid: data.item.uuid },
                                  },
                                },
                              },
                              [
                                _c("feather-icon", {
                                  staticClass: "cursor-pointer cursor",
                                  attrs: { icon: "EditIcon", size: "16" },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("div", { staticClass: "mx-2 mb-2 mt-2 paginate-area" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-center justify-content-sm-start col-md-6",
                },
                [
                  _c("span", {}, [
                    _vm._v(
                      " Foram encontrados " +
                        _vm._s(_vm.totalRows) +
                        " resultados "
                    ),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "col-md-6 d-flex align-items-center justify-content-center justify-content-sm-end",
                },
                [
                  _c("b-pagination", {
                    staticClass: "my-1",
                    attrs: {
                      "total-rows": _vm.totalRows,
                      "per-page": _vm.perPage,
                      limit: 10,
                      "first-number": "",
                      "last-number": "",
                      "prev-class": "prev-item",
                      "next-class": "next-item",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.updatePage()
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "prev-text",
                        fn: function () {
                          return [
                            _c("feather-icon", {
                              attrs: { icon: "ChevronLeftIcon", size: "18" },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "next-text",
                        fn: function () {
                          return [
                            _c("feather-icon", {
                              attrs: { icon: "ChevronRightIcon", size: "18" },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.currentPage,
                      callback: function ($$v) {
                        _vm.currentPage = $$v
                      },
                      expression: "currentPage",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }